import React, { useEffect } from 'react'
import { Box, Flex, Link, TextButton, Token } from '@revolut/ui-kit'
import { RepairTool } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { hasTestCycleCookie } from '@src/utils/reviewCycles'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { disableSandboxMode } from './common'
import { BannerRenderer, useBanner } from '../UIKitWithThemeProvider/BannerProvider'

const BANNER_KEY = 'testing-cycle-warning-bar'

const TestingCycleWarningBar = () => {
  const testCycleCookie = hasTestCycleCookie()
  const { show, hide } = useBanner()

  useEffect(() => {
    if (testCycleCookie) {
      show(BANNER_KEY)
    } else {
      hide(BANNER_KEY)
    }
  }, [testCycleCookie])

  return (
    <BannerRenderer bannerKey={BANNER_KEY} backgroundColor={Token.color.warning}>
      <Flex justifyContent="center" alignItems="center" gap="s-8" height="100%">
        <RepairTool size={16} />
        <Box>
          Sandbox mode enabled,{' '}
          <Link
            use={InternalLink}
            to={pathToUrl(ROUTES.SETTINGS.USER_PREFERENCES)}
            color={Token.color.white}
            textDecoration="underline"
          >
            limitations apply
          </Link>
          . You can control Sandbox mode in{' '}
          <Link
            use={InternalLink}
            to={pathToUrl(ROUTES.SETTINGS.USER_PREFERENCES)}
            color={Token.color.white}
            textDecoration="underline"
          >
            Settings
          </Link>{' '}
          or{' '}
          <TextButton
            color={Token.color.white}
            style={{ textDecoration: 'underline' }}
            onClick={disableSandboxMode}
          >
            Disable
          </TextButton>
        </Box>
      </Flex>
    </BannerRenderer>
  )
}

export default TestingCycleWarningBar
